<template>
  <div class="edu-frame">
    <van-nav-bar title="支付结果"/>
    <div class="content-frame">
      <div class="pay-frame">
        <img class="paylogo" src="../../../statics/images/zfcg.png">
        <div class="pay-res-tips">参加活动成功，请稍后咨询结果。</div>
        <van-button style="margin-top: 50px" square size="normal" type="default" @click="toActivitys">返回主页
        </van-button>
        <!--square-->
      </div>
      <!--<div class="pay-frame" style="display: none">
        <img class="paylogo" src="../../statics/images/zfsb.png">
        <div class="pay-res-tips">支付失败，如有问题，请联系相关人员处理。</div>
        <van-button style="margin-top: 50px" square size="normal" type="default" @click="toYbmbj">查看已报读班级
        </van-button>
      </div>-->
    </div>
  </div>
</template>
<script>
import {NavBar, Button} from "vant";

export default {
  components: {
    VanNavBar: NavBar,
    VanButton: Button,
  },
  methods: {
    toActivitys() {
      this.$router.replace({ name: 'vmain' })
    }
  }
}
</script>
<style scoped>
.paylogo {
  width: 120px;
  height: 120px;
  margin-top: 30%;
}

.pay-res-tips {
  font-size: 14px;
  margin-top: 20px;
  color: gray;
}
.pay-frame {
  display: flex;align-items: center;flex-direction: column;
}
</style>
